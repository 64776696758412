import React from "react";
import LoginHeader from "../../components/login-header";
import CompanyAwaitingApproved from "../../features/login/company-awaiting-approve";
import LoginForm from "../../features/login/log-in-form";
import { useMyInfo } from "../../hooks/user.hooks";

const Login = () => {
  const { data: userInfo } = useMyInfo();
  return (
    <div className="signup-container">
      <LoginHeader />
      <div className="login-body">
        {userInfo?.state === "WAITING_VERIFICATION" ? (
          <CompanyAwaitingApproved />
        ) : (
          <LoginForm />
        )}
      </div>
    </div>
  );
};

export default Login;
