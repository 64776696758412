import React from "react";
import { logout } from "../../utils/auth";
import { CustomOutlineButton } from "../../components/custom-button";

const CompanyAwaitingApproved = () => {
  const handleBackToLogin = () => {
    logout();
  };
  return (
    <div>
      <h1>Account Under Reviewing</h1>
      <div>
        Your account is under review. <br />
        Please contact our <a href="mailto:clint@wealthx.au">Help Center</a> if
        you need a hand. for help.
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}
      >
        <CustomOutlineButton
          label="Back to Login"
          onClick={handleBackToLogin}
        />
      </div>
    </div>
  );
};

export default CompanyAwaitingApproved;
